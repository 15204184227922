<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px">合同编号：</td>
                            <td>
                                <el-input v-model="searchItem.contractNum" size="small" placeholder="请输入合同号"></el-input>
                            </td>
                            <td style="width: 80px">公司名称：</td>
                            <td>
                                <el-input v-model="searchItem.companyName" size="small" placeholder="请输入公司名称"></el-input>
                            </td>
                            <td style="width: 80px">项目名称：</td>
                            <td style="width: 28%">
                                <el-input v-model="searchItem.projectName" size="small" placeholder="请输入项目名称"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 80px; padding-top: 10px">设备Imei：</td>
                            <td style="padding-top: 10px; width: 28%">
                                <el-input v-model="searchItem.imei" size="small" placeholder="请输入设备Imei"></el-input>
                            </td>
                            <td style="width: 80px; padding-top: 10px">对口销售：</td>
                            <td style="padding-top: 10px">
                                <el-select style="width: 100%" size="small" v-model="searchItem.userName" :clearable="true"
                                    placeholder="请选择销售">
                                    <el-option v-for="item in saleList" :key="item.id" :label="item.userName"
                                        :value="item.userName"></el-option>
                                </el-select>
                            </td>
                            <td style="text-align: center; padding-top: 10px; width: 31%" colspan="2">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-search" size="small" style="margin-left: 10%"
                                    @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" style="margin-left: 10%" size="small"
                                    id="createExcel" @click="exportExcel">导出EXCEL
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table :data="tableData" v-loading="loading" element-loading-text="拼命加载中"
                            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.1)"
                            :max-height="tableHeight" style="width: 100%; height: 100%" :border="true"
                            :row-style="{ height: '40px' }" :header-cell-style="{
                                background: '#EBEEF8',
                                height: '50px',
                                'text-align': 'center',
                            }" :cell-style="{ padding: '0', height: lineHeight }" fit>
                            <el-table-column fixed type="index" width="50" label="排序" align="center"></el-table-column>
                            <el-table-column fixed prop="companyName" label="公司名称" width="120" align="center"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column fixed prop="projectName" label="项目名称" width="120" align="center"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column fixed prop="sceneNo" label="现场编号" align="center"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column fixed prop="deviceType" label="设备类型" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.deviceType == 1">塔机</span>
                                    <span v-if="scope.row.deviceType == 2">升降机</span>
                                    <span v-if="scope.row.deviceType == 3">单盲吊</span>
                                </template></el-table-column>
                            <el-table-column fixed prop="imei" label="设备Imei" width="120" align="center"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column fixed prop="contractNum" label="合同编号" width="160" align="center"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="userName" label="对口销售" align="center"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contractServiceMonth" label="合同约定服务周期" width="150" align="center"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contractReceivables" label="合同总应收金额(元)" width="150" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.contractReceivables != 0">{{
                                        (scope.row.contractReceivables / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.contractReceivables == 0">0</span>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column
                                    prop="deviceReceivables"
                                    label="设备总应收金额(元)"
                                    width="150"
                                    align="center"
                                    show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                  <span v-if="scope.row.deviceReceivables != 0">{{
                    (scope.row.deviceReceivables / 100).toFixed(2)
                  }}</span>
                                    <span v-if="scope.row.deviceReceivables == 0">0</span>
                                </template>
                            </el-table-column
                            > -->
                            <el-table-column prop="invoiceAmount" label="合同已开票金额(元)" width="130" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.invoiceAmount != 0">{{
                                        (scope.row.invoiceAmount / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.invoiceAmount == 0">0</span>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column
                                    prop="towerCount"
                                    label="塔机数量"
                                    align="center"
                                    show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column
                                    prop="lifterCount"
                                    label="升降机数量"
                                    align="center"
                                    width="100"
                                    show-overflow-tooltip
                            ></el-table-column> -->
                            <el-table-column prop="deposit" label="押金(元)" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.deposit != 0">{{
                                        (scope.row.deposit / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.deposit == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="payCost" label="购买费用(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.payCost != 0">{{
                                        (scope.row.payCost / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.payCost == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="leaseCost" label="租赁费(元)" width="90" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.leaseCost != 0">{{
                                        (scope.row.leaseCost / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.leaseCost == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="serviceCost" label="服务费(元)" width="90" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.serviceCost != 0">{{
                                        (scope.row.serviceCost / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.serviceCost == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="activationDate" label="激活日期" align="center"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="returnMoneyInfo" label="回款信息" align="center"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="saleReceivables" label="销售应收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.saleReceivables != 0">{{
                                        (scope.row.saleReceivables / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.saleReceivables == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="saleReceived" label="销售实收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.saleReceived != 0">{{
                                        (scope.row.saleReceived / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.saleReceived == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="saleNotReceived" label="销售未收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.saleNotReceived != 0">{{
                                        (scope.row.saleNotReceived / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.saleNotReceived == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="ocsStartDate" label="ocs开始日期" align="center" width="120"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="ocsEndDate" label="ocs结束日期" align="center" width="120"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="leaseReceivables" label="租赁应收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.leaseReceivables != 0">{{
                                        (scope.row.leaseReceivables / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.leaseReceivables == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="leaseReceived" label="租赁实收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.leaseReceived != 0">{{
                                        (scope.row.leaseReceived / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.leaseReceived == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="leaseNotReceived" label="租赁未收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.leaseNotReceived != 0">{{
                                        (scope.row.leaseNotReceived / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.leaseNotReceived == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="leaseAdvanceReceived" label="租赁预收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.leaseAdvanceReceived != 0">{{
                                        (scope.row.leaseAdvanceReceived / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.leaseAdvanceReceived == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="leaseAdvanceDate" label="租赁预计停止时间" width="150" align="center"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="serviceReceivables" label="服务应收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.serviceReceivables != 0">{{
                                        (scope.row.serviceReceivables / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.serviceReceivables == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="serviceReceived" label="服务实收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.serviceReceived != 0">{{
                                        (scope.row.serviceReceived / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.serviceReceived == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="serviceNotReceived" label="服务未收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.serviceNotReceived != 0">{{
                                        (scope.row.serviceNotReceived / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.serviceNotReceived == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="receivables" label="当前应收(元)" width="150" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.receivables != 0">{{
                                        (scope.row.receivables / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.receivables == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="received" label="当前已收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.received != 0">{{
                                        (scope.row.received / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.received == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="notReceived" label="当前未收(元)" width="110" align="center"
                                show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.notReceived != 0">{{
                                        (scope.row.notReceived / 100).toFixed(2)
                                    }}</span>
                                    <span v-if="scope.row.notReceived == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="receivedRate" label="回款率" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.receivedRate }}%</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="searchItem.pageSize" :pageTotal="tableDataTotal"
                        :pageCurrent.sync="searchItem.pageIndex" :pageList="pageList" @handlePageChange="pageChange"
                        @handleSizeChange="pageSizeChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageList: [15, 20, 30, 50],
            tableHeight: "",
            textarea: "",
            tableData: [],
            tableDataTotal: 0,
            option: {
                pageSize: 15,
                pageIndex: 1,
            },
            totalPage: 10,
            lineHeight: "",
            searchItem: {
                companyName: "",
                contractNum: "",
                imei: "",
                projectName: "",
                userName: "",
                pageIndex: 1,
                pageSize: 15,
            },
            depotType: 0,
            addoption: {
                id: "",
                companyName: "",
                name: "",
                phone: "",
                idcard: "",
            },
            companyList: [
                {
                    value: "111",
                    label: "正式合同",
                },
                {
                    value: "222",
                    label: "测试合同",
                },
            ],
            deviceList: [
                {
                    senceNo: "1#",
                    type: 1,
                    id: 1,
                },
                {
                    senceNo: "2#",
                    type: 2,
                    id: 2,
                },
            ],
            dateTime: [],
            deviceMoneyList: [],
            saleList: [],
            exportHeader: [
                [
                    "公司名称",
                    "项目名称",
                    "对口销售",
                    "合同编号",
                    "合同约定服务周期",
                    "押金",
                    "购买费用",
                    "租赁费",
                    "服务费",
                    "合同总应收金额",
                    "合同已开票金额(元)",
                    "设备编号",
                    "设备类型",
                    "现场编号",
                    "激活日期",
                    "回款信息",
                    "销售应收款",
                    "销售实收款",
                    "销售未收款",
                    "ocs开始日期",
                    "ocs结束日期",
                    "租赁应收款",
                    "租赁实收款",
                    "租赁未收款",
                    "租赁预收款",
                    "租赁预计停止时间",
                    "服务应收款",
                    "服务实收款",
                    "服务未收款",
                    "当前应收款",
                    "当前已收款",
                    "当前未收款",
                    "回款率",
                ],
            ],
            exportTestData: [
                "companyName",
                "projectName",
                "userName",
                "contractNum",
                "contractServiceMonth",
                "deposit",
                "payCost",
                "leaseCost",
                "serviceCost",
                "contractReceivables",
                "invoiceAmount",
                "imei",
                "deviceType",
                "sceneNo",
                "activationDate",
                "returnMoneyInfo",
                "saleReceivables",
                "saleReceived",
                "saleNotReceived",
                "ocsStartDate",
                "ocsEndDate",
                "leaseReceivables",
                "leaseReceived",
                "leaseNotReceived",
                "leaseAdvanceReceived",
                "leaseAdvanceDate",
                "serviceReceivables",
                "serviceReceived",
                "serviceNotReceived",
                "receivables",
                "received",
                "notReceived",
                "receivedRate",
            ],
            tHeader: [
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
            ],
            fileName: "",
            mergeList: [],
            loading: false,
        };
    },
    methods: {
        getDictionary() {
            if (this.dateTime == null || this.dateTime.length <= 0) {
                this.dateTime[0] = "";
                this.dateTime[1] = "";
            }

            this.searchItem.startDate = this.dateTime[0];
            this.searchItem.endDate = this.dateTime[1];

            this.loading = true;
            this.$api.getChargingDevicesReport(this.searchItem).then((res) => {
                if (res.code == 200) {
                    this.loading = false;
                    this.tableData = res.data.content;
                    this.tableDataTotal = res.data.totalElements;
                }
            });
        },
        editMaterial(val) {
            this.dialogVisible = true;
            this.dialogName = "新增回款";
            this.addoption.id = "";
            this.addoption.companyName = "";
            this.addoption.name = "";
            this.addoption.phone = "";
            this.addoption.idcard = "";
        },
        pageChange(option) {
            this.searchItem.pageIndex = option;
            this.getDictionary();
        },
        pageSizeChange(val) {
            this.searchItem.pageIndex = 1;
            this.searchItem.pageSize = val;
            this.getDictionary();
        },
        searchBtn() {
            this.getDictionary();
        },
        reset() {
            this.searchItem = {
                companyName: "",
                contractNum: "",
                imei: "",
                startDate: "",
                userName: "",
                endDate: "",
                pageIndex: 1,
                pageSize: 15,
            };
            this.searchBtn();
        },
        getSaleList() {
            this.$api.getSaleUsers().then((res) => {
                if (res.code == 200) {
                    this.saleList = res.data;
                }
            });
        },
        exportExcel() {
            var item = {
                companyName: this.searchItem.companyName,
                contractNum: this.searchItem.contractNum,
                imei: this.searchItem.imei,
                projectName: this.searchItem.projectName,
                userName: this.searchItem.userName,
                pageIndex: 1,
                pageSize: 1000000,
            }
            document.getElementById("createExcel").style.background = "#687C7D";
            document.getElementById("createExcel").innerHTML = "正在生成...";
            document.getElementById("createExcel").disabled = "disabled";

            this.$message({
                type: 'warning',
                message: '正在生成报表!'
            });
            this.$api.getChargingDevicesReport(item).then((res) => {
                if (res.code == 200) {
                    var tableData = res.data.content;


                    for (var i = 1; i < 37; i++) {
                        this.mergeList.push(
                            this.convertToTitle(i) + "1:" + this.convertToTitle(i) + "2"
                        );
                    }
                    this.getSplicingDate();
                    this.getBasicData();
                    const multiHeader = this.exportHeader;
                    const filterVal = this.exportTestData;
                    const list = tableData; //这个是你循环数据到页面上的的变量名
                    const data = this.formatJson(filterVal, list);
                    const merges = this.mergeList;
                    import("../../utils/Export2Excel").then((excel) => {
                        excel.export_json_to_excel({
                            multiHeader,
                            header: this.tHeader,
                            merges,
                            data,
                            filename: this.fileName,
                        });
                    });
                    document.getElementById("createExcel").style.background = "#169bd5";
                    document.getElementById("createExcel").innerHTML = "导出EXCEL";
                    document.getElementById("createExcel").disabled = "";

                    this.$message({
                        type: 'success',
                        message: '下载成功!'
                    });
                }
            });

        },
        formatJson(filterVal, jsonData) {
            return jsonData.map((v) =>
                filterVal.map((j) => {
                    if (j === "timestamp") {
                        return v[j];
                    } else if (j === "deviceType") {
                        if (v[j] == 1) {
                            return "塔机";
                        } else {
                            return "升降机";
                        }
                    } else if (j === "receivedRate") {
                        var text = "" + v[j] + "%";
                        return text;
                    } else if (
                        j === "deposit" ||
                        j === "payCost" ||
                        j === "leaseCost" ||
                        j === "serviceCost" ||
                        j === "contractReceivables" ||
                        j === "deviceReceivables" ||
                        j === "invoiceAmount" ||
                        j === "saleReceivables" ||
                        j === "saleReceived" ||
                        j === "saleNotReceived" ||
                        j === "leaseReceivables" ||
                        j === "leaseReceived" ||
                        j === "leaseNotReceived" ||
                        j === "leaseAdvanceReceived" ||
                        j === "serviceReceivables" ||
                        j === "serviceReceived" ||
                        j === "serviceNotReceived" ||
                        j === "receivables" ||
                        j === "received" ||
                        j === "notReceived"
                    ) {
                        var num = (v[j] / 100).toFixed(2);
                        return num;
                    } else {
                        return v[j];
                    }
                })
            );
        },
        getSplicingDate() {
            var i = 1;
            var startDate = new Date(this.dateTime[0]);
            var endDate = new Date(this.dateTime[1]);
            while (startDate <= endDate) {
                this.exportHeader[0].push(
                    startDate.getMonth() +
                    1 +
                    "." +
                    (startDate.getDate() < 10
                        ? "0" + startDate.getDate()
                        : startDate.getDate())
                );
                this.exportHeader[0].push("");
                this.exportHeader[0].push("");
                startDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
                this.tHeader.push("收");
                this.tHeader.push("领");
                this.tHeader.push("发");
                this.exportTestData.push("accept");
                this.exportTestData.push("receive");
                this.exportTestData.push("send");
                this.mergeList.push(
                    this.convertToTitle(18 + i) +
                    "1:" +
                    this.convertToTitle(18 + 2 + i) +
                    "1"
                );
                i = i + 3;
            }
        },
        getBasicData() {
            var date = new Date();
            var year = date.getFullYear();
            var month =
                date.getMonth() + 1 < 10
                    ? 0 + "" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            var date = date.getDate() < 10 ? 0 + "" + date.getDate() : date.getDate();
            this.fileName = "销售回款对账单" + year + month + date;
        },
        convertToTitle(n) {
            const nums = [];
            let code = "A".charCodeAt(0);
            for (let i = code; i < code + 26; i++) {
                nums.push(String.fromCharCode(i));
            }
            let ans = [];
            let index = 0;
            do {
                n -= 1;
                ans.unshift(nums[n % 26]);
            } while ((n = Math.floor(n / 26)));
            return ans.join("");
        },
    },
    created() {
        this.getDictionary();
        this.getSaleList();
        this.tableHeight = window.innerHeight * 0.72;
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
    z-index: 1;
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 24px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 3px;
    display: block;
    cursor: pointer;
}

.opertion {
    display: flex;
    justify-content: center;
}

.iconbianji {
    margin-right: 10px;
    color: #20a0ff;
}

.iconshanchu {
    margin-left: 10px;
    color: red;
}

.page-body {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.table-body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.table-header {
    height: 100px;
    margin-top: 0.5%;
    background-color: #ffffff;
}

.handle-box {
    padding: 5px;
}

.table-info {
    height: 94%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

.table-button {
    flex: 1;
    margin: 0 1%;
}

.table-data {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

td {
    text-align: right;
    white-space: nowrap;
    /*控制单行显示*/
    overflow: hidden;
    /*超出隐藏*/
    text-overflow: ellipsis;
    /*隐藏的字符用省略号表示*/
}

.opertion {
    display: flex;
    justify-content: center;
}

.dialogdiv {
    div {
        text-align: center;
        margin-top: 10px;
    }

    p {
        width: 80px;
        text-align: left;
        display: inline-block;
    }
}

.deviceCollection {
    height: 40px;
    width: 100%;

    div {
        width: 30%;
        float: left;
    }

    .deviceInput {
        margin: 0;
        padding: 0;
    }

    p {
        text-align: center;
        margin-top: 8px;
    }
}
</style>